import BG from '~/lang/bg.json'
import EN from '~/lang/en.json'
import { Locale } from '@prisma/client'

export default defineI18nConfig(() => ({
    legacy: false,
    availableLocales: Object.values(Locale),
    locale: Locale.BG,
    messages: {
        [Locale.BG]: BG,
        [Locale.EN]: EN,
    },
}))
